import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "@fontsource/cairo";
import {history} from "./history";
import {Route, Router, Switch} from "react-router-dom";
import AllHere from "./components/AllHere";
import Thanks from "./components/thanks";
import NotFound from "./components/NotFound";
const App = () => {

    return (
        <>
            <Router history={history}>
                <Switch>

                    <Route  path="/blogs/:name"  component={AllHere}/>
                    <Route  path="/thanksform"><Thanks/></Route>
                    <Route  component={NotFound}/>
                </Switch>
            </Router>
        </>
    );
}

export default App;
