import React from "react";
import './style.css'
import {AiFillHeart} from "react-icons/ai";
import axios from "axios";
import {VscFileSymlinkFile} from "react-icons/vsc";
import {withRouter} from "react-router-dom";

class Description extends React.Component {
    state = {
        allData:{},
        features:[]

    };

    componentDidMount() {

        axios.get(process.env.REACT_APP_API_URL_FORM,{
            params:{
                url:`api/method/variiance_investor.variiance_investor_website.api.v2.investment_blog.get_blog?blog_obj=${this.props.match.params.name}`
            }
        }).then((res)=>{
             let allData = res.data.message;
             let features = allData.Features;
             this.setState({allData,features})
        })
    }

    render() {
        let {allData,features} = this.state;
        return (

            <>
                <div className='main-description'>
                    <div className='container'>
                        <div className='row'>

                            {/*The Main Image*/}
                            <div className='col-12'>
                                <div className='main-iamge-desc'>
                                    <img src={allData.image} alt='The Main Image Description'/>
                                </div>
                            </div>

                            {/*The Title And Description Image*/}
                            <div className='col-12'>

                                <h4>{allData.title}</h4>

                                <div className='edit-images' dangerouslySetInnerHTML={{__html: allData.description }}/>

                            </div>
                            {/*The Section Article*/}
                            <div className='col-12'>
                                <h5>ما تحدثنا عنه في هذه المقالة</h5>
                                <div className='row'>
                                    {features.map((resp,index) => {
                                        return(
                                            <div className='col-md-6 mb' key={Math.random()}>
                                                <div className='edit-all-dec'>
                                                    <span className='desc-number'>{index<9? 0:null}{index + 1}</span>
                                                    <span
                                                        className='desc-title'>{resp.title}</span>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                            {/*Add Button Like And Share*/}
                            <div className='add-section-share text-start mt-5'>
                                <div className="fb-like edit-facebook" data-href={window.location.href} data-width=""
                                     data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Description);
