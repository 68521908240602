import React from "react";
import logo from "../images/invest.PNG";

class NavBar extends React.Component {
    state = {

    };

    componentDidMount() {

    }


    render() {

        return (

            <>
                <div className='container'>
                    <nav className="navbar navbar-light mt-3 mb-3 flex-row-reverse">
                        <a className="navbar-brand edit-in-mobile" href="#">
                            <img src={logo}/>
                        </a>
                    </nav>
                </div>

            </>
        );
    }
}

export default NavBar;
