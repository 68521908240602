import React, {Component} from "react";
import './style.css'
import ThanksPage from '../images/aa.png'
import logo from "../images/invest.PNG";
import {withTranslation} from "react-i18next";
import { useHistory } from 'react-router-dom';

function Thanks(){
    let history = useHistory();
        return (
            <>
                <div className='main-section-thankForm'>
                    <div className='edit-div-thankform'>
                        <div className='edit-thanks-title-image'>
                            <img src={logo}/>
                            <h3>شكرا لك علي وقتك وملئ البيانات</h3>
                        </div>

                        <div className='edit-img-formthank'>
                            <img src={ThanksPage}/>
                        </div>
                        <div className='edit-title-formthank'>
                            <h4> سيتم التواصل معك قريبا</h4>
                            <button onClick={() => history.goBack()}  className='edit-btn-thanks'>شكرا لكم</button>
                        </div>
                    </div>
                </div>
            </>

        )
}

export default withTranslation()(Thanks);
