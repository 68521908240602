import React, {Component} from "react";
import {BsPhone} from 'react-icons/bs'
import {HiOutlineMail} from 'react-icons/hi'
import {AiOutlineUser} from "react-icons/ai";
import {BiShoppingBag} from "react-icons/bi";
import {GiProfit} from "react-icons/gi";
import {SiGooglemeet} from "react-icons/si";
import {FaCalendarDay} from "react-icons/fa";
import "moment/locale/ar";
import "moment/locale/en-au";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js'
import {Translation, withTranslation} from "react-i18next";
import i18n from "./i18n/i18n";
import cities from "./cities.json"
import 'antd/dist/antd.css';
import axios from "axios";
import {withRouter} from "react-router-dom";
import {Select} from "antd";


class FormInvest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iti1: {},
            isLoading: true,
            errors1: {},
            fullname: "",
            phonenumber: "",
            email: "",
            job: "",
            selectedOption: "",
            selectPreferMeeting: "",
            selectPreferMeetingDate: "",
            other_social: "",
            selectedOptionLooking1: "",
            message1: '',
            disableButton: false,
            formHide1: false,
            RequestType1: "",
            cities1: [],
            lang1: "",
            city1: "",
        };
        this.onValueChange = this.onValueChange.bind(this);
    }

    async componentDidMount() {
        const input1 = document.querySelector("#phone");
        let iti1 = intlTelInput(input1, {
            autoPlaceholder: "on",
            nationalMode: true,
            initialCountry: "eg",
            excludeCountries: ["il"],
            separateDialCode: true,
            preferredCountries: ["eg"],
            utilsScript: "intl-tel-input/build/js/utils.js",

        });
        this.setState({input1: input1, iti1: iti1})
        this.setState({cities: cities.cities})
    }

    handleSubmit = () => {
        if (this.handleValidation() === false) {
        } else {
            this.setState({disableButton: true})
            const newparams = {
                url: `/api/resource/Investment Page Form`,
                data: {
                    investment_page_blog: this.props.match.params.name,
                    fullname: this.state.fullname,
                    phonenumber: this.state.phonenumber,
                    email: this.state.email,
                    job: this.state.job,
                    invest_money: this.state.investMoney,
                    did_you_invest_before: this.state.selectedOption,
                    preferred_meeting: this.state.selectPreferMeeting,
                    preferred_meeting_day: this.state.selectPreferMeetingDate
                },
            };
            axios.post(process.env.REACT_APP_API_URL_FORM, newparams)
                .then((response) => {
                    let res = response.status;
                    if (res === 200) {
                        this.props.history.push('/thanksform')
                        this.setState({
                            investment_page_blog: "",
                            fullname: "",
                            phonenumber: "",
                            email: "",
                            job: "",
                            selectedOption: '',
                            investMoney: "",
                            selectPreferMeeting: "",
                            selectPreferMeetingDate: "",
                            disableButton: false,
                        });

                    } else {
                        this.setState({disableButton: false})

                    }
                });
        }

    }

    handleChangeGeneralInputs = (event, name) => {
        this.setState({[event.target.name]: event.target.value.replace(/\s\s/g, '').replace(/\.+/g, '.')}, () => {
            let errors1 = {};
            let formIsValid1 = true;
            if (name === "fullname") {
                if (!this.state.fullname) {
                    formIsValid1 = false;
                    errors1.fullname = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.fullname.length < 3) {
                    formIsValid1 = false;
                    errors1.fullname = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.fullname !== "undefined") {
                    if (!this.state.fullname.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                        formIsValid1 = false;
                        errors1.fullname = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors1.fullname = ""
                    formIsValid1 = true;

                }
            }
            if (name === "job") {
                if (!this.state.job) {
                    formIsValid1 = false;
                    errors1.job = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (this.state.job.length < 3) {
                    formIsValid1 = false;
                    errors1.job = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.job !== "undefined") {
                    if (!this.state.job.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                        formIsValid1 = false;
                        errors1.job = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors1.job = ""
                    formIsValid1 = true;
                }

            }

            if (name === "email") {
                if (!this.state.email) {
                    formIsValid1 = false;

                    errors1.email = <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                } else if (typeof this.state.email !== "undefined") {
                    if (
                        !this.state.email.match(
                            /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
                        )
                    ) {
                        formIsValid1 = false;
                        errors1.email = <Translation i18n={i18n}>
                            {
                                (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                            }
                        </Translation>
                    }
                } else {
                    errors1.email = ("")
                    formIsValid1 = true
                }
            }


            this.setState({errors1: errors1});
            return formIsValid1;
        });

    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value.replace(/[^0-9]+/ig, '')}, () => {
            if (!this.state.phonenumber) {
                this.setState({
                    phone_error1: <Translation i18n={i18n}>
                        {
                            (t, {i18n}) => <p>{t('field is required')}</p> // will be looked up from namespace ns1
                        }
                    </Translation>
                })
            } else {
                this.setState({
                    phone_error1: ''

                })
            }
            let input1 = this.state.input1
            let iti1 = this.state.iti1
            let errorMsg = document.querySelector("#error-msg1"),
                validMsg = document.querySelector("#valid-msg1")
            let errorMap = [this.props.t('Invalid number'), this.props.t('Invalid country code'), this.props.t('Too short'), this.props.t('Too long'), this.props.t('Invalid number')];

            let reset = function () {
                input1.classList.remove("error");
                errorMsg.innerHTML = "";
                errorMsg.classList.add("hide");
                validMsg.classList.add("hide");
            };

            reset();

            if (input1.value.trim()) {
                if (iti1.isValidNumber()) {
                    validMsg.classList.remove("hide");
                    this.setState({disabled: false})
                } else {
                    this.setState({disabled: true})
                    input1.classList.add("error");
                    let errorCode = iti1.getValidationError();
                    errorMsg.innerHTML = errorMap[errorCode];
                    errorMsg.classList.remove("hide");
                }
            }

        });

    };

    onValueChange = (event) => {
        this.setState({
            selectedOption: event.target.value,
        });
    }

    onValueChangeMeetDate = (e) => {
        {
            this.setState({selectPreferMeetingDate: e.target.value})
        }
    }

    onValueChangeMeet = (e) => {
        {
            this.setState({selectPreferMeeting: e.target.value})
        }
    }

    handleValidation = () => {
        let errors1 = {};
        let formIsValid1 = true;

        // Full Name //
        if (!this.state.fullname) {
            formIsValid1 = false;
            errors1.fullname = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (this.state.fullname.length < 3) {
            formIsValid1 = false;
            errors1.fullname = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.fullname !== "undefined") {
            if (!this.state.fullname.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                formIsValid1 = false;
                errors1.fullname = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        // Phone //
        if (!this.state.phonenumber) {
            formIsValid1 = false
            this.setState({
                phone_error1: <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t('field is required')}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            })
        }

        // Email //
        if (!this.state.email) {
            formIsValid1 = false;

            errors1.email = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        } else if (typeof this.state.email !== "undefined") {
            if (
                !this.state.email.match(
                    /^(?![^@]*([-._])\1)(?![^@]*([-.])(?:[^@]*\2){2})\w(?:[\w.-]*\w)?@(?:[a-zA-Z]+\.)+[a-zA-Z]+$/
                )
            ) {
                formIsValid1 = false;
                errors1.email = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Please enter valid mail")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        //Job//
        if (!this.state.job) {
            formIsValid1 = false;
            errors1.job = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot be empty")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (this.state.job.length < 3) {
            formIsValid1 = false;
            errors1.job = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Cannot less than three char")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        if (typeof this.state.job !== "undefined") {
            if (!this.state.job.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
                formIsValid1 = false;
                errors1.job = <Translation i18n={i18n}>
                    {
                        (t, {i18n}) => <p>{t("Cannot be empty and Only letters")}</p> // will be looked up from namespace ns1
                    }
                </Translation>
            }
        }

        //SelectOption
        if (!this.state.selectedOption) {
            formIsValid1 = false;
            errors1.selectedOption = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Please select from options")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.selectPreferMeetingDate) {
            formIsValid1 = false;
            errors1.selectedOption = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Please select from options")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }
        if (!this.state.selectPreferMeeting) {
            formIsValid1 = false;
            errors1.selectedOption = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Please select from options")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        //investMoney
        if (!this.state.investMoney) {
            formIsValid1 = false;
            errors1.investMoney = <Translation i18n={i18n}>
                {
                    (t, {i18n}) => <p>{t("Much Investment must be specified")}</p> // will be looked up from namespace ns1
                }
            </Translation>
        }

        this.setState({errors1: errors1})
        return formIsValid1;

    }

    handleSelect = (value) => {
        this.setState({investMoney: value});
    };

    render() {
        let {t} = this.props
        const InvestMoney = [{
            label: t("million-5millions"), value: t("million-5millions")
        }, {
            label: t("5millions-10million"), value: t("5millions-10million")
        }, {
            label: t("more than 10million"), value: t("more than 10million")
        }]
        return (
            <>
                <div className='container-fluid mt-4 addBackgroundHere'>
                    <div className='row'>
                        <div className='col-12  edit-inMobile'>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="col-md-12">
                                    <h4 className='text-center'>تواصل معنا</h4>
                                    <p className='edit-text-form'>اذا كنت تريد معرفة معلومات أكتر للاستثمار في شركة
                                        ناشئة صاعدة سجل هنا</p>

                                    {/*First  Input  Full Name*/}
                                    <div className="user-input-wrp">
                                        <br/>
                                        <input type="text" className="inputText form-control" required
                                               id="inlineFormInputGroupUsername"
                                               name="fullname"
                                               value={this.state.fullname}
                                               onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'fullname')}
                                               onChange={(e) => this.handleChangeGeneralInputs(e, 'fullname')}/>
                                        <span className="floating-label"><AiOutlineUser/> {t('username')}</span>
                                        <span className="err">{this.state.errors1.fullname}</span>
                                    </div>

                                    {/*Input Phone Number*/}
                                    <div className="col-lg-12 mt-4 text-center">
                                        <div className='form-control edit-here-form'>
                                            <div className="input-group">
                                                <div className="input-group-text"><BsPhone/></div>
                                                <input className="form-control edit-here-forms"
                                                       name="phonenumber"
                                                       id="phone"
                                                       type="tel"
                                                       placeholder={t('Phone Number')}
                                                       value={this.state.phonenumber}
                                                       onChange={this.handleChange}
                                                       onSelectCapture={this.handleChange}
                                                       autoComplete="tel"/>
                                            </div>
                                        </div>
                                        <span id="valid-msg1"
                                              className="hide valid"> {t('✓ Valid')}</span>
                                        <span id="error-msg1" className="err"></span>
                                        <span className="err">{this.state.phone_error1}</span>
                                    </div>

                                    {/*Input Email-Address*/}
                                    <div className="user-input-wrp mt-4">
                                        <br/>
                                        <input type="text" className="inputText form-control" required
                                               aria-label="email"
                                               value={this.state.email}
                                               name="email"
                                               onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'email')}
                                               onChange={(e) => this.handleChangeGeneralInputs(e, 'email')}
                                               aria-describedby="addon-wrapping"/>
                                        <span className="floating-label"><HiOutlineMail/> {t('email')}</span>
                                        <span className="err">{this.state.errors1.email}</span>
                                    </div>

                                    {/*Input Enter Your Job*/}
                                    <div className="user-input-wrp mt-4">
                                        <br/>
                                        <input type="text" className="inputText form-control" required
                                               id="inlineFormInputGroupUsername"
                                               name="job"
                                               value={this.state.job}
                                               onSelectCapture={(e) => this.handleChangeGeneralInputs(e, 'job')}
                                               onChange={(e) => this.handleChangeGeneralInputs(e, 'job')}/>
                                        <span
                                            className="floating-label"><BiShoppingBag/> {t('How many years main')}</span>
                                        <span className="err">{this.state.errors1.job}</span>
                                    </div>

                                    {/*Add Select Items*/}
                                    <div className="user-input-wrp mt-4 ">
                                        <div className="inputText form-control">
                                            <Select
                                                classNamePrefix="select"
                                                placeholder={t("How much invest")}
                                                value={this.state.investMoney}
                                                name="investMoney"
                                                options={InvestMoney}
                                                onChange={this.handleSelect}
                                            />
                                        </div>
                                        <span className="err">{this.state.errors1.investMoney}</span>
                                    </div>

                                    {/* Choose Day Of Meeting*/}
                                    <div className="col-lg-12 mb-3 edit-parent-input-contact-us text-center">
                                        <div className='edit-info-form text-end mb-4 pe-1'>
                                                    <span
                                                        style={{
                                                            color: '#41A4F6',
                                                            fontSize: "20px",
                                                            paddingLeft: "7px"
                                                        }}><FaCalendarDay/></span>
                                            <span style={{
                                                color: 'white',
                                                fontSize: '14px',
                                                fontWeight: "bold"
                                            }}>اختر اليوم المناسب للتواصل ؟</span>
                                        </div>
                                        <div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio">
                                                    <input type="radio"  value="Sunday"
                                                           checked={this.state.selectPreferMeetingDate === "Sunday"}
                                                           onChange={this.onValueChangeMeetDate}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">الأحد</span>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio ">
                                                    <input type="radio"  value="Tuesday"
                                                           checked={this.state.selectPreferMeetingDate === "Tuesday"}
                                                           onChange={this.onValueChangeMeetDate}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">الثلاثاء</span>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio">
                                                    <input type="radio"  value="Thursday"
                                                           checked={this.state.selectPreferMeetingDate === "Thursday"}
                                                           onChange={this.onValueChangeMeetDate}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">الخميس</span>
                                                </label>
                                            </div>
                                        </div>
                                        <span className="err">{this.state.errors1.selectPreferMeetingDate}</span>
                                    </div>

                                    {/* Choose The Best time */}
                                    <div className="col-lg-12 mb-3 edit-parent-input-contact-us text-center">
                                        <div className='edit-info-form text-end mb-4 pe-1'>
                                                    <span
                                                        style={{
                                                            color: '#41A4F6',
                                                            fontSize: "20px",
                                                            paddingLeft: "7px"
                                                        }}><GiProfit/></span>
                                            <span style={{
                                                color: 'white',
                                                fontSize: '14px',
                                                fontWeight: "bold"
                                            }}>{t("Did You Invest Before")}</span>
                                        </div>
                                        <div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio">
                                                    <input type="radio"  value="Yes"
                                                           checked={this.state.selectedOption === "Yes"}
                                                           onChange={this.onValueChange}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">{t('Yes')}</span>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio ">
                                                    <input type="radio"  value="No"
                                                           checked={this.state.selectedOption === "No"}
                                                           onChange={this.onValueChange}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">{t('No')}</span>
                                                </label>
                                            </div>
                                        </div>
                                        <span className="err">{this.state.errors1.selectedOption}</span>
                                    </div>

                                    {/* Preferred Meeting */}
                                    <div className="col-lg-12 mb-3 edit-parent-input-contact-us text-center">
                                        <div className='edit-info-form text-end mb-4 pe-1'>
                                                    <span
                                                        style={{
                                                            color: '#41A4F6',
                                                            fontSize: "20px",
                                                            paddingLeft: "7px"
                                                        }}><SiGooglemeet/></span>
                                            <span style={{
                                                color: 'white',
                                                fontSize: '14px',
                                                fontWeight: "bold"
                                            }}>الطريقه التي بها تفضل الميتينج ؟</span>
                                        </div>
                                        <div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio">
                                                    <input type="radio"  value="Physical"
                                                           checked={this.state.selectPreferMeeting === "Physical"}
                                                           onChange={this.onValueChangeMeet}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">Physical</span>
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline edit-here">
                                                <label className="inputRadio ">
                                                    <input type="radio"  value="Virtual"
                                                           checked={this.state.selectPreferMeeting === "Virtual"}
                                                           onChange={this.onValueChangeMeet}
                                                    />
                                                    <span className="checkmark"></span>
                                                    <span className="coloring">Virtual</span>
                                                </label>
                                            </div>
                                        </div>
                                        <span className="err">{this.state.errors1.selectPreferMeeting}</span>
                                    </div>

                                </div>

                            </form>

                        </div>
                        <div className="nextCenterd-flex m-auto mb-3">
                            <button className="btn-form-edit d-flex m-auto"
                                    disabled={this.state.disabled || this.state.disableButton}
                                    onClick={this.handleSubmit}>
                                {t('Submit')}
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(withRouter(FormInvest));
