import React from "react";
// import {FBComments} from 'facebook-plugins';
// import photo from '../images/picture area1.png'
// import photo2 from '../images/picture area3.png'
// import {AiFillHeart} from "react-icons/ai";

class CommentSection extends React.Component {
    state = {};

    render() {
        return (
            <>
                <div
                    className="fb-comments "
                    data-colorscheme="dark"
                    data-href={window.location.href} data-width="100%"
                     data-numposts="5">
                </div>

                {/*<div>*/}
                {/*    <div>*/}
                {/*        <FBComments appId="yourFacebookAppId"*/}
                {/*                    href="#"*/}
                {/*                    width={750}*/}
                {/*                    numPosts={5}*/}
                {/*                    locale="fr_CA"/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className='main-section-comment'>*/}
                {/*    <div className='container'>*/}

                {/*        /!*To Write Comment*!/*/}
                {/*        <div className='single-comment'>*/}
                {/*            <div className='add-photo'>*/}
                {/*                <img src={photo}/>*/}
                {/*            </div>*/}
                {/*            <div className='add-comment'>*/}
                {/*                <textarea placeholder='اكتب تعليق هنا ...' cols="200" rows="4"></textarea>*/}
                {/*                <div className='edit-btn-comment'>*/}
                {/*                    <button>ارسل التعليق</button>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        /!*To Show Count Comment*!/*/}
                {/*        <div className='add-count-comment mt-5'>*/}
                {/*            <div className='edit-count-comment'>*/}
                {/*                <h4>يوجد (15) تعليق</h4>*/}
                {/*                <p className='add-line'></p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <hr/>*/}
                {/*        /!*Add Comment To Show Feedback*!/*/}
                {/*        <div className='single-sub-comment'>*/}
                {/*            <div className='add-photo'>*/}
                {/*                <img src={photo2}/>*/}
                {/*            </div>*/}
                {/*            <div className='add-sub-comment'>*/}
                {/*                <span> تاريخ النشر:</span>*/}
                {/*                <span className='edit-comment-date'> 8/17/2022</span>*/}
                {/*                <h6>محمد مصطفي علي</h6>*/}
                {/*                <p>هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد*/}
                {/*                    النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى*/}
                {/*                    زيادة عدد الحروف التى يولدها التطبيق.*/}
                {/*                </p>*/}
                {/*                /!*<div className='edit-btn-sub'>*!/*/}
                {/*                /!*    <div className='edit-sub-like'>*!/*/}
                {/*                /!*        <AiFillHeart/> <span>4</span>  اعاجب*!/*/}
                {/*                /!*    </div>*!/*/}
                {/*                /!*    <div className='edit-sub-share'>*!/*/}
                {/*                /!*        <a href=''>رد علي التعليق</a>*!/*/}
                {/*                /!*    </div>*!/*/}
                {/*                /!*</div>*!/*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <hr/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}

export default CommentSection;
