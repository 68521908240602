import React,{Component} from "react";
import NavBar from "./Navbar";
import Description from "./description";
import FormInvest from "./form";
import CommentSection from "./comment";
import {withRouter} from "react-router-dom";
class AllHere extends Component {
    state={
        isLoading: true
    }
    render(){

        return (
            <>
                <NavBar/>
                <Description/>
                <FormInvest/>
                <CommentSection/>
            </>
        );
    }
}

export default withRouter(AllHere);
