import React from "react";
import {withTranslation} from "react-i18next";
import Img404 from "../images/404.png";
import ImgNotFound from "../images/notfound.png";
import {Link} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';


class NotFound extends React.Component {


    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row py-5 Page404">
                        <div className="col-lg-6">
                            <div className="my-5 px-5 text-center">
                                <div className="div404">
                                    <LazyLoadImage src={Img404} alt="404 not found" className="Img404"/>
                                    <p className="NotfoundTxt my-3"> PAGE NOT FOUND</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="my-5 px-5 text-center">
                                <LazyLoadImage src={ImgNotFound} alt="404 not found" className="Img404"/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(NotFound);
